export function tab(tabButtonEl, tabContentsEl) {
  if ($(tabButtonEl).length && $(tabContentsEl).length) {

    var hash = window.location.hash;
    if (hash == "#items") {
      $(tabButtonEl).children().removeClass("active");
      $(hash).addClass("active");
      const index = $(hash).index(this);
      $(tabContentsEl).children().removeClass("active");
      $(tabContentsEl).children().eq(index).addClass("active");
    }

    $(tabButtonEl).children().on("click", function () {
      const index = $(tabButtonEl).children().index(this);
      $(tabButtonEl).children().removeClass("active");
      $(tabContentsEl).children().removeClass("active");
      $(this).addClass("active");
      $(tabContentsEl).children().eq(index).addClass("active");
    });
  }

  $(".pageReload").on('click', function () {
    var url = $(this).attr("href");
    window.location.href = url;
    location.reload(true);
  });
}
