import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export function slider() {
  // topPage slider
  const swiper = new Swiper('.swiper-top', {
    loop: true,
    effect: "fade",
    allowTouchMove: false,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    speed: 2000,
  });
}
