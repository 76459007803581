export function pageScroll() {
  $(function () {
    const hash = location.hash;
    let headerHeight = $('.l-header').outerHeight();

    if (hash == '#items') {
      $("html, body").stop().scrollTop(0);
      setTimeout(function () {
        const target = $(hash),
          position = target.offset().top - headerHeight - 500;
        $("html, body").animate({
          scrollTop: position
        }, 500, "swing");
      });
    } else {
      $("html, body").stop().scrollTop(0);
      setTimeout(function () {
        const target = $(hash),
          position = target.offset().top - headerHeight - 20;
        $("html, body").animate({
          scrollTop: position
        }, 500, "swing");
      });
    }
  });
}
