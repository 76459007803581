import {
  hamburgerMenuButton
} from "./components/hamburgerMenuButton";
import {
  pageScroll
} from "./components/scroll"
import {
  slider
} from "./components/slider";
import {
  tab
} from "./components/tab";



$(document).ready(function () {
  (() => {
    /*
    ハンバーガーメニュー用関数
    */
    //ハンバーガーメニューボタンクラス定義
    const buttonEl = "#hamburgerButton";
    //ハンバーガーメニュークラス定義
    const menuEl = "#hamburgerMenu";
    hamburgerMenuButton(buttonEl, menuEl);
  })();

  (() => {
    pageScroll();
  })();

  (() => {
    /*
    スライダー用関数
    */
    slider();
  })();

  (() => {
    /*
    タブ用関数
    */
    //タブボタンラッパークラス定義
    const tabButtonEl = ".tabButton";
    //タブコンテンツラッパークラス定義
    const tabContentsEl = ".tabContent";
    tab(tabButtonEl, tabContentsEl);
  })();
});
