export function hamburgerMenuButton(buttonEl, menuEl) {
  if ($(buttonEl).length) {
    $("#hamburgerButton").on("click", function () {
      $('.p-hamburger__line-wrap').toggleClass('active');
      if($('.p-hamburger__line-wrap').hasClass('active')){
        $('.p-hamburger__txt').text("閉じる");
      }else{
        $('.p-hamburger__txt').text("メニュー");
      }
      $(menuEl).toggleClass("active");
    });
  }
}
